import React from 'react';
import Meta from './../components/Meta';
import ContactSection from './../components/ContactSection';
import HomeFooter from '../components/HomeFooter';

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <section className="py-10 px-4 my-10">
        <div className="container mx-auto mt-12">
          <div className="prose prose-a:text-blue-600 max-w-none">
            <h1>About Us</h1>

            <p>
              Welcome to Showrunner, your go-to platform for [describe the services/products your
              company offers]. Founded in [year], our mission is to [briefly state your mission or
              vision]. We are dedicated to delivering innovative solutions that empower our
              customers and enhance their experience.
            </p>

            <h2>Our Story</h2>
            <p>
              What began as a small idea has grown into a thriving business, thanks to the passion
              and dedication of our team. At Showrunner, we believe in pushing the boundaries of
              what’s possible, constantly evolving to meet the needs of our clients. With years of
              industry experience, we’ve built a platform that is not only reliable but also
              user-friendly and tailored to help you succeed.
            </p>

            <p>
              We specialize in [list your products/services or areas of expertise], offering
              [describe how your company provides value]. Whether you’re looking for [specific
              service/product] or [another service/product], we’ve got you covered.
            </p>

            <h2>Meet the Team</h2>
            <p>
              Our talented team is made up of industry experts who are passionate about making a
              difference. From developers to customer support, every member of the Showrunner team
              plays a crucial role in driving our company forward. At Showrunner, we combine
              expertise, innovation, and a customer-centric approach to provide top-tier solutions.
              Our commitment to [specific client benefits or unique selling points] sets us apart
              from the competition.
            </p>

            <h2>Contact Us</h2>
            <p>
              We’d love to hear from you! Whether you have questions about our services, need
              support, or want to collaborate, feel free to get in touch at{' '}
              <a href="mailto:info@showrunner.co">info@showrunner.co</a>.
            </p>

            <p>Thank you for choosing Showrunner. We look forward to working with you!</p>
          </div>
        </div>
      </section>
      <ContactSection />
      <HomeFooter sticky />
    </>
  );
}

export default AboutPage;
