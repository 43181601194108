import { useRef, useEffect } from 'react';
import supabase from './supabase';
import { formatDateToYYYYMMDD } from './data-factory';

// Make an API request to `/api/{path}`
export async function apiRequest(path, method = 'GET', data) {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session ? session.access_token : undefined;

  return fetch(`/api/${path}`, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 'error') {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === 'auth/invalid-user-token') {
          supabase.auth.signOut();
        }

        throw new CustomError(response.code, response.message);
      } else {
        return response.data;
      }
    });
}

// Make an API request to any external URL
export function apiRequestExternal(url, method = 'GET', data) {
  return fetch(url, {
    method: method,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: data ? JSON.stringify(data) : undefined,
  }).then(response => response.json());
}

// Create an Error with custom message and code
export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

// Hook that returns previous value of state
export function usePrevious(state) {
  const ref = useRef();
  useEffect(() => {
    ref.current = state;
  });
  return ref.current;
}

// Format search string to be used in textSearch supabase query.
// In Postgres, the tsquery type does not allow unescaped whitespace
// because it treats whitespace as a separator between lexemes (words).
export function formatSearchString(searchString) {
  return searchString.includes(' ') ? searchString.split(' ').join(' & ') : searchString;
}

// a function to get shows from the database based on sub-filters
// multi-select filters are passed as sets
export async function getShows({
  open,
  sortedByRelevance,
  selectedMediums,
  selectedLocation,
  selectedSubject,
  selectedStyles,
}) {
  const today = formatDateToYYYYMMDD(new Date());
  const twoWeeksFromNow = formatDateToYYYYMMDD(new Date(Date.now() + 14 * 24 * 60 * 60 * 1000));

  let query = supabase
    .from('shows')
    .select('*, venues!inner(neighborhood, venue_name, latitude, longitude, address1)')
    .eq('venues.city', 'New York')
    .gte('end_date', today);

  if (selectedLocation.size > 0) {
    query.in('venues.neighborhood', [...selectedLocation]);
  }

  if (selectedMediums.size > 0) {
    query.overlaps('show_medium', [...selectedMediums]);
  }

  if (selectedStyles.size > 0) {
    query.overlaps('show_style', [...selectedStyles]);
  }

  if (selectedSubject.size > 0) {
    query.overlaps('show_subject_matter', [...selectedSubject]);
  }

  let timeConditions = [];
  if (open.has('On View')) {
    query = query.lt('start_date', today).gte('end_date', today);
    query.order('show_relevance', { ascending: false });
  }
  if (open.has('Opening Today')) {
    timeConditions.push(`start_date.eq.${today}`);
    timeConditions.push(`opening_date.eq.${today}`);
    const combinedtimeConditions = timeConditions.join(',');
    query = query.or(combinedtimeConditions);
    query.order('show_relevance', { ascending: false });
  }
  if (open.has('Opening Soon')) {
    timeConditions.push(`start_date.gte.${today}`);
    const combinedtimeConditions = timeConditions.join(',');
    query = query.or(combinedtimeConditions);
    query.order('opening_sort', { ascending: true });
  }
  if (open.has('Closing Soon')) {
    timeConditions.push(`end_date.gte.${today}`);
    timeConditions.push(`end_date.lte.${twoWeeksFromNow}`);
    const combinedtimeConditions = timeConditions.join(',');
    query = query.or(combinedtimeConditions);
    query.order('closing_sort', { ascending: true });
  }

  if (sortedByRelevance) {
    query.order('show_relevance', { ascending: false });
  }

  const { data, error } = await query;
  //console.debug(data);

  if (error) {
    throw error;
  }

  return data;
}

// a function to get a single show by its id
export async function getShowById(showId) {
  const { data, error } = await supabase
    .from('shows')
    .select(
      '*, venues!inner(neighborhood, venue_name, city, address1, phone, latitude, longitude, image_urls )'
    )
    .eq('show_id', showId);

  if (error) {
    throw error;
  }

  return data;
}

export async function getShowsByIDs(showIds) {
  const { data, error } = await supabase
    .from('shows')
    .select(
      '*, venues!inner(neighborhood, venue_name, city, address1, phone, latitude, longitude, image_urls )'
    )
    .in('show_id', showIds);

  if (error) {
    throw error;
  }

  return data;
}

// a function to get list objects from the lists tables that match owner id
export async function getListsByOwner(owner) {
  const { data, error } = await supabase.from('saved_lists').select('*').eq('owner', owner);
  if (error) {
    throw error;
  }
  const listsWithFirstShow = await Promise.all(
    data.map(async list => {
      if (list.shows.length > 0) {
        const res = await supabase.from('shows').select('*').eq('show_id', list.shows[0]);

        if (res.error) {
          throw res.error;
        }

        return {
          ...list,
          first_show_data: res.data[0],
        };
      }

      return list;
    })
  );

  return listsWithFirstShow;
}

// a function to get a single list by its id
export async function getListById(listId) {
  const { data, error } = await supabase.from('saved_lists').select('*').eq('id', listId);
  if (error) {
    throw error;
  }

  const listWithShows = await Promise.all(
    data.map(async list => {
      if (list.shows.length > 0) {
        const res = await supabase
          .from('shows')
          .select('*, venues!inner(neighborhood, venue_name, longitude, latitude)')
          .in('show_id', list.shows);

        if (res.error) {
          throw res.error;
        }

        return {
          ...list,
          shows_data: res.data,
        };
      }

      return list;
    })
  );

  return listWithShows;
}
