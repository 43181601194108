import React, { useState } from 'react';
import ReauthModal from './ReauthModal';
import SettingsGeneral from './SettingsGeneral';
//import SettingsPassword from './SettingsPassword';
//import SettingsBilling from './SettingsBilling';
import SettingsNotifications from './SettingsNotifications';
import { useAuth } from './../util/auth';

function SettingsSection(props) {
  const auth = useAuth();
  const [formAlert, setFormAlert] = useState(null);

  // State to control whether we show a re-authentication flow
  // Required by some security sensitive actions, such as changing password.
  const [reauthState, setReauthState] = useState({
    show: false,
  });

  const validSections = {
    general: true,
    password: true,
    billing: true,
  };

  const section = validSections[props.section] ? props.section : 'general';

  // Handle status of type "success", "error", or "requires-recent-login"
  // We don't treat "requires-recent-login" as an error as we handle it
  // gracefully by taking the user through a re-authentication flow.
  const handleStatus = ({ type, message, callback }) => {
    if (type === 'requires-recent-login') {
      // First clear any existing message
      setFormAlert(null);
      // Then update state to show re-authentication modal
      setReauthState({
        show: true,
        // Failed action to try again after reauth
        callback: callback,
      });
    } else {
      // Display message to user (type is success or error)
      setFormAlert({
        type: type,
        message: message,
      });
    }
  };

  return (
    <section className="py-10 px-4">
      {reauthState.show && (
        <ReauthModal
          callback={reauthState.callback}
          provider={auth.user.providers[0]}
          onDone={() => setReauthState({ show: false })}
        />
      )}
      {/* OLD CODE --- Review and remove
        <div className="flex justify-center space-x-5">
          <Link
            to="/settings/general"
            className={"" + (section === "general" ? " underline" : "")}
          >
            General
          </Link>
          <Link
            to="/settings/password"
            className={"" + (section === "password" ? " underline" : "")}
          >
            Password
          </Link>
          <Link
            to="/settings/billing"
            className={"" + (section === "billing" ? " underline" : "")}
          >
            Billing
          </Link>
        </div>
        <div className="container mx-auto mt-5 max-w-md">
          {formAlert && (
            <div
              className={
                "mb-4" +
                (formAlert.type === "error" ? " text-red-600" : "") +
                (formAlert.type === "success" ? " text-green-600" : "")
              }
            >
              {formAlert.message}
            </div>
          )}

          {section === "general" && <SettingsGeneral onStatus={handleStatus} />}
          {section === "password" && <SettingsPassword onStatus={handleStatus} />}
          {section === "billing" && <SettingsBilling onStatus={handleStatus} />}
        </div> */}

      {section === 'general' && <SettingsGeneral onStatus={handleStatus} />}

      {/* Alert message */}
      {formAlert && (
        <div
          role="alert"
          className={
            'flex alert max-w-xs md:max-w-sm mx-auto mt-3' +
            (formAlert.type === 'error' ? ' alert-error' : '') +
            (formAlert.type === 'success' ? ' alert-success' : '')
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="h-6 w-6 shrink-0 stroke-current"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <span>{formAlert.message}</span>
        </div>
      )}

      <SettingsNotifications />
    </section>
  );
}

export default SettingsSection;
