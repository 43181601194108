import React, { useState, useCallback, useEffect } from 'react';
import { MapPinIcon, BoltIcon, HeartIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { HeartIcon as HeartIconSolid } from '@heroicons/react/20/solid';
import { history, Link } from './../util/router';
import { useAuth } from './../util/auth';
import { getListsByOwner } from '../util/util';
import SaveShowModal from './SaveShowModal';
import { formatDate, formatTimeRange, formatDateToYYYYMMDD } from '../util/data-factory';

const Content = ({ shows }) => {
  const auth = useAuth();

  const [itemsToShow, setItemsToShow] = useState(50);
  const [likedShows, setLikedShows] = useState(new Set());
  const [savingItem, setSavingItem] = useState(false);
  const [currentShowLiked, setCurrentShowLiked] = useState(null);
  const [userLists, setUserLists] = useState([]);

  const fetchUserLists = useCallback(async () => {
    try {
      if (auth.user) {
        const lists = await getListsByOwner(auth.user.id);
        setUserLists(lists);
        if (lists.length) {
          const likedShows = new Set();
          lists.forEach(list => {
            list.shows.forEach(show => {
              likedShows.add(show);
            });
          });
          setLikedShows(likedShows);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [auth.user]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight
      )
        return;
      setItemsToShow(itemsToShow + 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [itemsToShow]);

  useEffect(() => {
    fetchUserLists();
  }, [auth.user, fetchUserLists]);

  const handleLike = showId => {
    if (!auth.user) {
      history.replace('/auth/signin');
    } else {
      setSavingItem(true);
      setCurrentShowLiked(showId);
    }
  };

  const onSaved = () => {
    setSavingItem(false);
    fetchUserLists();
  };

  const today = formatDateToYYYYMMDD(new Date());

  return (
    <div className="w-screen mt-10 overflow-hidden">
      <div className="flex w-full max-w-[78rem] md:p-8 justify-center mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          {shows.slice(0, itemsToShow).map(show => (
            <div
              key={show.show_id}
              className="p-4 bg-gray-100 overflow-auto uppercase font-medium text-black border-b"
            >
              {show?.image_urls[0] && (
                <Link href="/show/[id]" to={`/show/${show.show_id}`}>
                  <div className="relative md:max-h-[390px] mb-1 md:mb-5 overflow-hidden content-end">
                    {show.recommended && (
                      <div className="absolute top-2 left-2 py-0 px-2 bg-off-white">
                        <small>Trending</small>
                      </div>
                    )}
                    <img className="min-w-full m-auto" src={show.image_urls[0]} alt="showrunner" />
                  </div>
                </Link>
              )}

              <Link href="/show/[id]" to={`/show/${show.show_id}`}>
                <h1 className="text-2xl md:text-4xl my-1">{show.display_title}</h1>
              </Link>
              <p className="text-secondary-red text-sm md:text-lg">{show.top_tags.join(' | ')}</p>

              <div className="flex justify-between text-xs md:text-sm my-4">
                <div className="flex flex-1 flex-wrap">
                  <div className="flex mr-4 mb-2 items-center">
                    <MapPinIcon className="w-5" /> {show.venues.venue_name}
                  </div>
                  {show.recommended ? (
                    <div className="flex mr-4 mb-2 items-center">
                      <BoltIcon className="w-5" /> Trending
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="flex mr-4 mb-2 items-center">
                    <CalendarIcon className="w-5 mr-1" /> {formatDate(show.start_date)} -{' '}
                    {formatDate(show.end_date)}
                  </div>
                </div>

                <div className="flex-none cursor-pointer" onClick={() => handleLike(show.show_id)}>
                  {likedShows.has(show.show_id) ? (
                    <HeartIconSolid className="w-6" />
                  ) : (
                    <HeartIcon className="w-6" />
                  )}
                </div>
              </div>
              {show.opening_date && show.opening_date >= today && (
                <div className="flex normal-case">
                  <p className="text-sm mr-2 uppercase">Opening Reception: </p>
                  <p className="text-sm mr-2 text-black/50">
                    {formatDate(show.opening_date, true)}
                  </p>
                  <p className="text-sm text-black/50">
                    {formatTimeRange(show.opening_start_time, show.opening_end_time)}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {savingItem && auth.user && (
        <SaveShowModal onDone={onSaved} showId={currentShowLiked} userLists={userLists} />
      )}
    </div>
  );
};

export default Content;
