import { useEffect, useState, useCallback, Fragment, useRef } from 'react';
import { useRouter, Link } from '../util/router';
import {
  MapPinIcon,
  BoltIcon,
  CalendarIcon,
  TrashIcon,
  ShareIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import Meta from '../components/Meta';
import maplibregl from 'maplibre-gl';
import { Source, Layer, Map, Popup, GeolocateControl, NavigationControl } from 'react-map-gl';
import PIN_ICON from '../assets/images/pin.png';
import PIN_ICON_REG from '../assets/images/pin2.png';

import { getListById } from '../util/util';
import { removeShowFromList } from '../util/db';
import { formatDate, trendingShowsLayer, regularShowsLayer } from '../util/data-factory';
import { useWebShare } from '../hooks';
import { useAuth } from '../util/auth';
import { useMedia } from 'react-use';

const SingleListPage = () => {
  const mapRef = useRef(null);
  const router = useRouter();
  const auth = useAuth();
  const { id } = router.query;
  const { shareContent } = useWebShare();
  const isWide = useMedia('(min-width: 480px)');

  const [list, setList] = useState();
  const [featureData, setFeatureData] = useState();
  const [initialViewState, setInitialViewState] = useState();
  const [showId, setShowId] = useState();
  const [canDelete, setCanDelete] = useState(false);
  const [isPrivate, setPrivate] = useState(true);
  const [showMobileMap, setShowMobileMap] = useState(false);

  const [mapPopupInfo, setMapPopupInfo] = useState();
  const [mapImageInfo, setMapImageInfo] = useState();
  const [mapTopTagsInfo, setMapTopTagsInfo] = useState();

  const fetchListByID = useCallback(async () => {
    try {
      if (id) {
        const data = await getListById(id);
        setList(data[0]);
        if (auth.user && data[0].owner === auth.user.id) {
          setCanDelete(true);
          setPrivate(false);
        }

        if (!auth.user || data[0].owner !== auth.user.id) {
          if (data[0].is_private) {
            setPrivate(true);
          } else {
            setPrivate(false);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [id, auth.user]);

  useEffect(() => {
    fetchListByID();
  }, [id, fetchListByID]);

  const removeShow = async (listId, showId) => {
    try {
      await removeShowFromList(listId, showId);
      fetchListByID();
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Format filtered data as features to be
   * read as clusters by map-gl. Updates when filtered
   * data changes.
   */
  useEffect(() => {
    if (!list) return;

    const formatted_data = list.shows_data.map(i => {
      let longitude = parseFloat(i.venues.longitude);
      let latitude = parseFloat(i.venues.latitude);
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
        properties: { ...i, lat: latitude, lng: longitude },
      };
    });

    if (!formatted_data) return;
    setFeatureData({ type: 'FeatureCollection', features: formatted_data });
  }, [list]);

  /**
   * Set initial view state for map
   */
  useEffect(() => {
    if (!featureData) return;

    setInitialViewState({
      longitude: featureData.features[0].properties.lng,
      latitude: featureData.features[0].properties.lat,
      zoom: 13,
    });
  }, [featureData]);

  /**
   * Handles logic when click event on markers
   */
  const onPinClick = useCallback(event => {
    const feature = event.features && event.features[0];
    if (!feature) return;
    const layer = feature.layer;

    if (layer.id === trendingShowsLayer.id || layer.id === regularShowsLayer.id) {
      //const mapboxSource = mapRef.current.getSource('my-data');
      //console.debug(mapboxSource);
      event.originalEvent.stopPropagation();
      setShowId(feature.properties.show_id);
      //setMapImageInfo(JSON.parse(feature.properties.image_urls));
      setMapPopupInfo({ ...feature.properties, venues: JSON.parse(feature.properties.venues) });
      setMapImageInfo(JSON.parse(feature.properties.image_urls));
      setMapTopTagsInfo(JSON.parse(feature.properties.top_tags));
    } else {
      event.originalEvent.stopPropagation();
    }
  }, []);

  const handleShareClick = () => {
    shareContent({
      files: null,
      title: 'Showrunner',
      text: 'Check this list I created with my favorite shows and galleries',
      url: window.location.href,
    });
  };

  //const closeShowMapDialog = () => {
  //  setShowId(null);
  //  setMapImageInfo(null);
  //};

  const closeShowMapDialog = () => {
    setMapPopupInfo(null);
    setMapImageInfo(null);
    setMapTopTagsInfo(null);
  };

  if (isPrivate)
    return (
      <Fragment>
        <Meta title="Single List" />
        <section className="w-screen h-[100px] overflow-hidden bg-black/90"></section>
        <div className="flex sm:flex-row w-screen mt-2 h-[calc(100vh-100px)] overflow-hidden">
          <h2 className="text-center my-auto mx-auto text-xl">
            Oh no, seems like this is a private list of shows.
          </h2>
        </div>
      </Fragment>
    );

  return (
    <Fragment>
      <Meta title="Single List" />
      <section className="w-screen h-[100px] overflow-hidden bg-white"></section>

      {/* Show Map/List Button */}
      {!isPrivate && !isWide && (
        <div className="absolute top-[calc(100vh-110px)] left-[calc(50vw-80px)] w-40 text-center z-20">
          <button
            className="btn sm:btn-sm md:btn-md bg-secondary-red rounded-3xl"
            onClick={() => setShowMobileMap(!showMobileMap)}
          >
            {showMobileMap ? 'Show List' : 'Show Map'}
          </button>
        </div>
      )}

      {!isPrivate && (
        <div className="flex flex-col sm:flex-row w-screen mt-2 h-[calc(100vh-100px)] overflow-auto sm:overflow-hidden">
          <div className="w-full sm:w-[50%] max-w-[90rem] md:p-4 justify-center mx-auto">
            <div className="flex w-full py-2 px-3 my-auto mx-auto justify-between">
              <h1 className="uppercase text-xl md:text-2xl font-medium opacity-70">
                {list?.list_name ?? ''}
              </h1>
              <button className="btn btn-sm mx-2 my-auto" onClick={handleShareClick}>
                <ShareIcon className="w-4" />
              </button>
            </div>

            {/* Mobile List */}
            {!showMobileMap && !isWide && (
              <div className={`flex flex-col max-h-full overflow-y-auto px-1`}>
                {list &&
                  list.shows_data.map(show => (
                    <div
                      key={show.show_id}
                      className={`p-3 bg-gray-100 uppercase font-medium text-black leading-10 border-b ${
                        showId === show.show_id
                          ? 'border-4 border-b-4 bg-white rounded-lg border-regal-blue'
                          : ''
                      }`}
                    >
                      {show?.image_urls[0] && (
                        <Link href="/show/[id]" to={`/show/${show.show_id}`}>
                          <div className="relative md:max-h-[390px] mb-1 md:mb-5 overflow-hidden content-end cursor-pointer">
                            {show.recommended && (
                              <div className="absolute top-2 left-2 py-0 px-2 bg-off-white">
                                <small>Trending</small>
                              </div>
                            )}
                            <img
                              className="min-w-full m-auto"
                              src={show.image_urls[0]}
                              alt="showrunner"
                            />
                          </div>
                        </Link>
                      )}
                      <Link href="/show/[id]" to={`/show/${show.show_id}`}>
                        <h1 className="text-md md:text-2xl my-1">{show.display_title}</h1>
                      </Link>
                      <p className="text-secondary-red text-xs md:text-[1rem] leading-normal">
                        {show.top_tags.join(' | ')}
                      </p>

                      <div className="flex justify-between text-xs md:text-sm my-2 sm:my-4 items-center font-normal">
                        <div className="flex flex-1 flex-wrap capitalize">
                          <div className="flex mr-4 py-1 items-center">
                            <MapPinIcon className="w-5" /> {show.venues?.venue_name}
                          </div>
                          {show.recommended ? (
                            <div className="flex mr-4 py-1 items-center">
                              <BoltIcon className="w-5" /> Trending
                            </div>
                          ) : (
                            ''
                          )}
                          <div className="flex mr-4 py-1 items-center">
                            <CalendarIcon className="w-5 mr-1" /> {formatDate(show.start_date)} -{' '}
                            {formatDate(show.end_date)}
                          </div>
                        </div>
                        {canDelete && (
                          <div
                            className="flex-none cursor-pointer items-center"
                            onClick={() => removeShow(list.id, show.show_id)}
                          >
                            <TrashIcon className="w-5 text-dark-red" />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            )}

            {/* Desktop List */}
            {isWide && (
              <div
                className={`grid grid-cols-2 grid-flow-row-dense gap-4 h-[90%] overflow-auto px-1`}
              >
                {list &&
                  list.shows_data.map(show => (
                    <div
                      key={show.show_id}
                      className={`p-3 bg-gray-100 uppercase font-medium text-black leading-10 border-b ${
                        showId === show.show_id
                          ? 'border-4 border-b-4 bg-white rounded-lg border-regal-blue'
                          : ''
                      }`}
                    >
                      {show?.image_urls[0] && (
                        <Link href="/show/[id]" to={`/show/${show.show_id}`}>
                          <div className="relative md:max-h-[390px] mb-1 md:mb-5 overflow-hidden content-end">
                            {show.recommended && (
                              <div className="absolute top-2 left-2 py-0 px-2 bg-off-white">
                                <small>Trending</small>
                              </div>
                            )}
                            <img
                              className="min-w-full m-auto"
                              src={show.image_urls[0]}
                              alt="showrunner"
                            />
                          </div>
                        </Link>
                      )}
                      <Link href="/show/[id]" to={`/show/${show.show_id}`}>
                        <h1 className="text-md md:text-xl my-1">{show.display_title}</h1>
                      </Link>
                      <p className="text-secondary-red text-xs md:text-[0.90rem] leading-normal">
                        {show.top_tags.join(' | ')}
                      </p>

                      <div className="flex justify-between text-xs md:text-sm my-2 sm:my-4 items-center font-normal">
                        <div className="flex flex-1 flex-wrap capitalize">
                          <div className="flex mr-4 py-1 items-center">
                            <MapPinIcon className="w-5" /> {show.venues?.venue_name}
                          </div>
                          {show.recommended ? (
                            <div className="flex mr-4 py-1 items-center">
                              <BoltIcon className="w-5" /> Trending
                            </div>
                          ) : (
                            ''
                          )}
                          <div className="flex mr-4 py-1 items-center">
                            <CalendarIcon className="w-5 mr-1" /> {formatDate(show.start_date)} -{' '}
                            {formatDate(show.end_date)}
                          </div>
                        </div>
                        {canDelete && (
                          <div
                            className="flex-none cursor-pointer items-center"
                            onClick={() => removeShow(list.id, show.show_id)}
                          >
                            <TrashIcon className="w-5 text-dark-red" />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>

          {/* Map */}
          {(isWide || showMobileMap) && (
            <div className="flex relative w-full sm:w-[50%] h-full max-w-[90rem] md:p-0 justify-center mx-auto">
              {list && list.shows_data.length && initialViewState && (
                <Map
                  ref={mapRef}
                  initialViewState={initialViewState}
                  mapLib={maplibregl}
                  mapStyle="https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json"
                  interactiveLayerIds={[regularShowsLayer.id, trendingShowsLayer.id]}
                  minZoom={1.25}
                  maxZoom={20}
                  onClick={onPinClick}
                  onLoad={async () => {
                    const map = mapRef.current.getMap();
                    if (!map.hasImage('pin-icon')) {
                      const image = await map.loadImage(PIN_ICON);
                      map.addImage('pin-icon', image.data);
                    }
                    if (!map.hasImage('pin-icon-reg')) {
                      const image = await map.loadImage(PIN_ICON_REG);
                      map.addImage('pin-icon-reg', image.data);
                    }
                  }}
                >
                  <GeolocateControl trackUserLocation />
                  <NavigationControl />
                  <Source
                    id="my-data"
                    type="geojson"
                    data={featureData}
                    clusterMaxZoom={11}
                    clusterRadius={100}
                  >
                    <Layer {...regularShowsLayer} />
                    <Layer {...trendingShowsLayer} />
                  </Source>

                  {mapImageInfo && (
                    <Popup
                      anchor="right"
                      className="rounded-xl bg-red h-20 w-20"
                      latitude={mapPopupInfo?.lat}
                      longitude={mapPopupInfo?.lng}
                      closeOnClick={false}
                      onClose={closeShowMapDialog}
                    >
                      <div
                        className="absolute top-3 right-3 z-30 w-6 h-6 p-1 bg-white rounded-xl cursor-pointer"
                        onClick={closeShowMapDialog}
                      >
                        <XMarkIcon className="my-auto" />
                      </div>
                      <div className="bg-white h-auto rounded-xl overflow-hidden relative p-2">
                        {mapImageInfo.length ? (
                          <div className="h-40 md:h-60 w-40 md:w-60">
                            <img
                              className="h-[95%] mx-auto object-cover"
                              alt="_image"
                              src={mapImageInfo[0]}
                            />
                          </div>
                        ) : (
                          <Fragment></Fragment>
                        )}

                        {mapPopupInfo && (
                          <Link href="/show/[id]" to={`/show/${mapPopupInfo.show_id}`}>
                            <div className="flex flex-col mt-2 p-2 text-wrap w-40 md:w-60 cursor-pointer">
                              <p className="uppercase text-sm">{mapPopupInfo.show_title}</p>
                              <p className="text-sm text-black/60">
                                at {mapPopupInfo.venues.venue_name}
                              </p>
                              <p className="text-md text-black/60">
                                {mapPopupInfo.venues.address1}
                              </p>
                              <p className="text-xs text-secondary-red">
                                {mapTopTagsInfo?.join(' | ')}
                              </p>
                              <div className="flex mt-1">
                                <CalendarIcon className="w-4 mr-1" />
                                <p className="text-xs text-slate-500">
                                  {formatDate(mapPopupInfo.start_date)} -{' '}
                                  {formatDate(mapPopupInfo.end_date)}
                                </p>
                              </div>
                            </div>
                          </Link>
                        )}
                      </div>
                    </Popup>
                  )}
                </Map>
              )}
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default SingleListPage;
