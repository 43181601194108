import React from 'react';
import { MapPinIcon, BoltIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { Link } from './../util/router';
import { formatDate } from '../util/data-factory';

const SecondaryShowTile = ({ show, today }) => {
  return (
    <div className="p-2 bg-gray-100 overflow-auto text-black">
      {show?.image_urls[0] && (
        <Link href="/show/[id]" to={`/show/${show.show_id}`}>
          <div className="relative md:max-h-[390px] mb-1 md:mb-5 overflow-hidden content-end">
            {show.recommended && (
              <div className="absolute top-2 left-2 py-0 px-2 bg-off-white">
                <small>Trending</small>
              </div>
            )}
            <img className="min-w-full m-auto" src={show.image_urls[0]} alt="showrunner" />
          </div>
        </Link>
      )}

      <Link href="/show/[id]" to={`/show/${show.show_id}`}>
        <h1 className="text-lg uppercase font-medium">{show?.display_title}</h1>
      </Link>

      <div className="flex justify-between my-1 text-xs text-black/60">
        <div className="flex flex-1 flex-wrap">
          <div className="flex mr-4 mb-2 items-center">
            <MapPinIcon className="w-5" /> {show.venues.venue_name}
          </div>
          {show.recommended && (
            <div className="flex mr-4 mb-2 items-center">
              <BoltIcon className="w-5" /> Trending
            </div>
          )}
          <div className="flex mr-4 mb-2 items-center">
            <CalendarIcon className="w-5 mr-1" /> {formatDate(show.start_date)} -{' '}
            {formatDate(show.end_date)}
          </div>
        </div>
        <div className="flex-none cursor-pointer"></div>
      </div>
    </div>
  );
};

export default SecondaryShowTile;
