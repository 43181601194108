import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const MultiSelectDropdown = ({ label, tags, selectedTags, count, handleSelect }) => (
  <div className="flex relative mx-4">
    <Menu as="div" className="relative inline-block text-left">
      <div className="indicator">
        <span
          className={`indicator-item indicator-start badge bg-secondary-red text-white ${
            count === 0 && 'hidden'
          }`}
        >
          {count}
        </span>
        <Menu.Button className="flex text-black font-medium uppercase leading-tight ring-none focus:outline-none focus:ring-none">
          <span className="block truncate ml-3">{label}</span>
          <ChevronDownIcon className="ChevronDown w-6 h-6 stroke-2 px-1 justify-center items-center inline-flex" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute -left-10 z-10 mt-2 w-80 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="p-4">
            {tags.map((tag, idx) => (
              <div
                key={idx}
                className={`badge md:badge-lg m-1 cursor-pointer ${
                  selectedTags.has(tag) ? 'bg-secondary-red text-white' : 'badge-primary'
                }`}
                onClick={() => handleSelect(tag)}
              >
                {tag}
              </div>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  </div>
);

export default MultiSelectDropdown;
